import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link , graphql} from "gatsby"
import Img from "gatsby-image"

const NotFoundPage = (data) => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="l-error">
        <div className="container">
          <div className="l-error__wrapper">
            <p className="l-error__content__number">404</p>
            <div className="l-error__content">
               
                <p className="l-error__content__title">Oups</p>
                <p className="l-error__content__subtitle">Cette page n’existe pas ou n’existe plus.</p>
                <p className="l-error__content__link">
                  <Link to="/">Retour à l’accueil</Link>
                </p>
                
            
            </div>
            <div className="l-error__picture">
              <Img  objectPosition="0% 0%" fluid={data.data.error.childImageSharp.fluid} />
            </div>
          </div>
        </div>
        
    </div>
  </Layout>
)

export default NotFoundPage
export const query = graphql`
  query {
    error:file(relativePath: { eq: "error.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
   
  },
`  